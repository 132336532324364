var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-dialog',{attrs:{"scrollable":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"grey","rounded":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-variant")]),_vm._v(" Filter ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Select Filters")]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('v-radio-group',{attrs:{"column":""},model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}},_vm._l((_vm.filterChips),function(ref){
var label = ref.label;
return _c('v-radio',{key:label,attrs:{"label":label}})}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Save")])],1)],1)],1)],_vm._l((_vm.filterChips),function(ref){
var label = ref.label;
var color = ref.color;
return _c('v-btn',{key:label,staticClass:"ma-1",attrs:{"color":color,"rounded":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(_vm._s(label))],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }