































import { ref } from '@vue/composition-api';
import IndexTable from '@/components/IndexTable.vue';
import {
  MANAGE_STAKE_HOLDERS_HEADER,
  MANAGE_STAKE_HOLDERS_ITEMS
} from '@/constants/manage-program';

import ManageFilter from '../components/ManageFilter.vue';

export default {
  name: 'Managestakeholder',
  components: {
    IndexTable,
    ManageFilter
  },
  setup() {
    return {
      header: ref(MANAGE_STAKE_HOLDERS_HEADER),
      items: ref(MANAGE_STAKE_HOLDERS_ITEMS)
    };
  },
  data() {
    return {};
  }
};
