







































import { computed, defineComponent, ref } from '@vue/composition-api';
import { FILTER_CHIPS } from '@/constants/manage-program';

export default defineComponent({
  props: {
    value: {
      type: Array as () => string[],
      default() {
        return [];
      }
    }
  },
  setup(props, { emit }) {
    // state
    const dialog = ref(false);
    const selectedFilters = computed({
      get: () => props.value,
      set: newVal => {
        emit('input', newVal);
      }
    });
    return {
      dialog,
      selectedFilters,
      filterChips: ref(FILTER_CHIPS)
    };
  }
});
