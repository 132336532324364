













































































































import { ref } from '@vue/composition-api';
import IndexTable from '@/components/IndexTable.vue';
import { PARTICIPANTS_HEADERS, PARTICIPANTS_ITEMS } from '@/constants/manage-program';

export default {
  name: 'Participants',
  components: {
    IndexTable
  },
  setup() {
    return { header: ref(PARTICIPANTS_HEADERS), items: ref(PARTICIPANTS_ITEMS) };
  },
  data() {
    return {
      dialogm1: '',
      dialog: false,
      switch1: true,
      switch2: false
    };
  }
};
